export default class ActiveAnchor {
  constructor(el) {
    this.$el = $(el);
    this.anchorLinks = this.$el.find("a[href*=\\#]");
    this.anchorPoints = $("a[name]");
    this.anchorPositions = this.getAnchorPositions();
    this.activeClass = "active";

    this.setActiveLink(
      this.anchorPositions[this.anchorPositions.length - 1].name
    );
    this.bindScroll();
  }

  getAnchorPositions() {
    // Get simple array of all anchor names to check against anchor links within
    // HTML body
    const $anchorNames = [];
    this.anchorLinks.each(function () {
      $anchorNames.push($(this).attr("href").slice(1));
    });

    // Create positions array
    const $positions = [];
    this.anchorPoints.each(function () {
      // Add anchors to positions array if they exist in the element anchor list
      if ($anchorNames.indexOf($(this).attr("name")) > -1) {
        $positions.push({
          name: `#${$(this).attr("name")}`,
          position: $(this).offset().top,
        });
      }
    });
    $positions.sort(function (a, b) {
      let out = 0;
      if (a.position > b.position) {
        out = -1;
      }
      if (a.position < b.position) {
        out = 1;
      }
      return out;
    });

    return $positions;
  }

  getActiveAnchor(scrollPos) {
    let $currentAnchor = "";
    const $firstAnchor = this.anchorPositions[this.anchorPositions.length - 1];
    if (scrollPos < $firstAnchor.position) {
      $currentAnchor = $firstAnchor.name;
    } else {
      for (const position of Array.from(this.anchorPositions)) {
        // Currently subtracting 10 from anchor position to ensure active link on click
        if (scrollPos >= position.position - 112) {
          $currentAnchor = position.name;
          break;
        }
      }
    }
    return $currentAnchor;
  }

  setActiveLink(anchorName) {
    this.anchorLinks.each(function () {
      if ($(this).attr("href") === anchorName) {
        $(this).parent("li").addClass(this.activeClass);
      } else {
        $(this).parent("li").removeClass(this.activeClass);
      }
    });
  }

  bindScroll() {
    $(window).scroll(() => {
      this.setActiveLink(this.getActiveAnchor($(window).scrollTop()));
    });
  }
}
