export default class StillsFader {
  constructor(el) {
    this.duration = 400;
    this.$el = $(el);
    this.$stills = this.$el.find(".still");
    this.activeStillIndex = 0;
    this.fading = false;
    this.fadeTimeout = null;

    this.startFader();

    this.$el.on({
      stopFader: () => {
        this.stopFader();
      },
    });

    this.$el.on({
      startFader: () => {
        this.startFader();
      },
    });
  }

  switchStill() {
    let nextStillIndex = this.activeStillIndex + 1;
    if (nextStillIndex >= this.$stills.length) {
      nextStillIndex = 0;
    }
    clearTimeout(this.fadeTimeout);
    this.fadeTimeout = setTimeout(() => {
      $(this.$stills[this.activeStillIndex]).fadeTo(this.duration, 0);
      $(this.$stills[nextStillIndex]).fadeTo(0, 1);
      this.activeStillIndex = nextStillIndex;
      this.switchStill();
    }, 2000);
  }

  startFader() {
    clearTimeout(this.fadeTimeout);
    this.fading = true;
    $(this.$stills[this.activeStillIndex]).fadeTo(0, 1);
    this.switchStill();
  }

  stopFader() {
    clearTimeout(this.fadeTimeout);
    this.fading = false;
    $(this.$stills[this.activeStillIndex]).fadeTo(this.duration, 0);
    this.activeStillIndex = 0;
    $(this.$stills[this.activeStillIndex]).fadeTo(0, 1);
  }
}
