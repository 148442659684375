export default class Submenu {
  constructor(el) {
    this.activeClass = "submenu-active";
    this.$el = $(el);

    this.bindEvents();
  }

  bindEvents() {
    this.$el.mouseover(() => {
      this.showSubmenu();
    });
    this.$el.mouseout(() => {
      this.hideSubmenu();
    });
    this.$el.focusin(() => {
      this.showSubmenu();
    });
    this.$el.focusout(() => {
      this.hideSubmenu();
    });
  }

  showSubmenu() {
    this.$el.addClass(this.activeClass);
  }

  hideSubmenu() {
    this.$el.removeClass(this.activeClass);
  }
}
