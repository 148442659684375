export default class OverlayHash {
  constructor(overlay, options) {
    this.defaultActiveClass = "open";
    this.defaultDataAttr = "overlayHash";
    // Figure out default options
    if (options === null) {
      options = {};
    }
    this.activeClass = options.activeClass
      ? options.activeClass
      : this.defaultActiveClass;
    const dataAttr = options.dataAttr ? options.dataAttr : this.defaultDataAttr;

    this.$overlay = $(overlay);
    this.hash = this.$overlay.data(dataAttr).toLowerCase();

    this.bindHash();
  }

  openOverlay() {
    this.$overlay.addClass(this.activeClass);
    $("body").addClass("no-scroll open");
  }

  bindHash() {
    const currentHash = location.hash;
    if (currentHash.length) {
      // Trim down the hash if there is one
      const trimHash = currentHash.substring(1).toLowerCase();

      if (trimHash === this.hash) {
        this.openOverlay();
      }
    }
  }
}
