export default class SignupSegment {
  constructor(el, options = null) {
    this.$el = $(el);

    if (options) {
      this.hash = options.hash;
      this.query = options.query;
      this.header = options.header;

      this.changeForm();
    } else {
      // eslint-disable-next-line no-console
      console.log(
        "signupSegment options not set! Provide values for hash, query, and header."
      );
    }
  }

  changeForm() {
    const currentHash = window.location.hash.substr(1);
    const formAction = this.$el.attr("action");
    const formHeader = $("[data-signup-header]");

    if (currentHash.length && currentHash === this.hash) {
      this.$el.attr("action", formAction + this.query);
      formHeader.text(this.header);
    }
  }
}
