export default class ScreenVideo {
  // https://vimeo.com/246146911
  // https://youtu.be/UuLL6b2XtRw
  // https://player.vimeo.com/external/246146911.hd.mp4?s=2ccae1b3a5972230c2aa96a212a0fd387e12843a&profile_id=174
  constructor(el, callback) {
    let left, left1;
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.isOverlay = this.isOverlay.bind(this);
    this.isPaused = this.isPaused.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.onEnded = this.onEnded.bind(this);
    this.isActiveUser = this.isActiveUser.bind(this);
    this.isInactiveUser = this.isInactiveUser.bind(this);
    this.$el = $(el);
    this.$parent = $(el).parents(".screen-video");
    const source = this.$el.data("videoSource");
    this.type = parseInt(this.$el.data("videoType"));
    const params = this.$el.data("videoParams");
    this.autoplay =
      (left = params.indexOf("autoplay") >= 0) != null ? left : { true: false };
    this.loop =
      (left1 = params.indexOf("loop") >= 0) != null ? left1 : { true: false };
    if (this.type === 2) {
      this.type = "vimeo";
    }
    if (this.type === 1) {
      this.type = "youtube";
    }

    import(/* webpackChunkName: "video.js" */ "../lib/video.js").then(
      ({ default: videojs }) => {
        if (this.isDirectLink(source)) {
          this.screenVid = videojs(
            el,
            {
              fluid: true,
              controlBar: {
                volumeMenuButton: {
                  inline: false,
                  vertical: true,
                },
              },
            },
            callback
          );
        } else {
          this.screenVid = videojs(
            el,
            {
              fluid: true,
              controlBar: {
                volumeMenuButton: {
                  inline: false,
                  vertical: true,
                },
              },
              techOrder: ["vimeo", "youtube"],
              sources: [{ type: `video/${this.type}`, src: source }],
            },
            callback
          );
        }

        if (!this.screenVid) {
          return;
        } else {
          this.$el.on("contextmenu", (e) => e.preventDefault());
        }

        this.$dividerTime = this.$parent.find(".vjs-time-divider");
        this.$durationTime = this.$parent.find(".vjs-duration");
        this.$currentTime = this.$parent.find(".vjs-current-time");
        this.$bigPlayButton = this.$parent.find(".vjs-big-play-button");
        this.$volumeButton = this.$parent.find(".vjs-volume-menu-button");
        this.$fullscreenButton = this.$parent.find(".vjs-fullscreen-control");

        this.$dividerTime.after(this.$currentTime);
        this.$dividerTime.before(this.$durationTime);
        this.$volumeButton.before(this.$fullscreenButton);
        $("[data-screen-mobile-play]").append(this.$bigPlayButton);

        this.playing = false;

        this.screenVid.on("useractive", this.isActiveUser);
        this.screenVid.on("userinactive", this.isInactiveUser);
        this.screenVid.on("ended", this.onEnded);
        this.screenVid.on("play", this.onPlay);

        if (this.autoplay && this.type === "vimeo") {
          this.screenVid.play();
        }
      }
    );
  }

  onTimeUpdate() {
    this.isPaused();
    return this.isOverlay();
  }

  isOverlay() {
    const $overlay = $("[data-overlay]");

    if ($overlay.length && $overlay.hasClass("overlay-open") && this.playing) {
      return this.screenVid.pause();
    }
  }

  isPaused() {
    if (this.screenVid.paused()) {
      this.playing = false;
      $("body").addClass("vjs-paused");
      $("body").removeClass("vjs-playing");

      if ($("[data-stills]").length) {
        $("[data-stills]").trigger("startFader");
      }
    } else if (!this.playing) {
      this.playing = true;
      $("body").removeClass("vjs-paused");
      $("body").addClass("vjs-playing");

      if ($("[data-stills]").length) {
        $("[data-stills]").trigger("stopFader");
      }
    }
  }

  onPlay() {
    this.screenVid.on("timeupdate", this.onTimeUpdate);
    this.playing = true;
    $("body").removeClass("vjs-paused");
    $("body").addClass("vjs-playing");

    if ($("[data-stills]").length) {
      $("[data-stills]").trigger("stopFader");
    }
  }

  onEnded() {
    this.screenVid.off("timeupdate", this.onTimeUpdate);

    if (this.loop && this.type === "vimeo") {
      setTimeout(() => {
        this.screenVid.pause().play();
      }, 1000);
    }

    $("body").removeClass("vjs-paused");
    $("body").removeClass("vjs-playing");
    $(".vjs-play-control").removeClass("vjs-playing");

    if ($("[data-stills]").length) {
      $("[data-stills]").trigger("startFader");
    }
  }

  isActiveUser(e) {
    return $("body").addClass("vjs-active-user");
  }

  isInactiveUser(e) {
    return $("body").removeClass("vjs-active-user");
  }

  isDirectLink(url) {
    return url.indexOf("player.vimeo.com") >= 0;
  }
}
