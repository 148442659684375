import objectFitImages from "object-fit-images";
import rescroll from "./rescroll";
import { ExternalProductQuantity, GalleryExtras } from "./behaviors";
import { FlickityCarousel } from "./custom-elements";

require("../../../../modules/system/assets/js/framework.js");
require("../../../../modules/system/assets/js/framework.extras.js");
require("./lib/headroom/headroom.min.js");
require("./lib/headroom/jQuery.headroom.min.js");
require("./lib/fit-vids/jquery.fitvids.js");
require("./lib/cic-veggieburger/veggieburger.js");
require("./lib/featherlight/featherlight.min.js");
require("./lib/featherlight/featherlight.gallery.min.js");
require("./lib/match-height/jquery.matchHeight-min.js");
require("./ajax.js");
require("./extendJQuery");

export default class MocaTheme {
  init() {
    this._initStickyHeaders();
    this._initFitVids();
    this._initVeggieburgers();
    this._initAnchors();
    this._initTabs();
    this._initReadMoreContent();
    this._initStillsFaders();
    this._initGalleries();
    this._initDataFilters();
    this._initCaptions();
    this._initFragmentedForms();
    this._initGoogleSearch();
    this._initExternalProductQuantities();
    this._initOverlays();
    this._initColumnizers();
    this._initScreenVideo();
    this._initLocations();
    this._initSignupSegments();
    this._initSetAlertBarDimensions();
    this._initObjectFit();
    this._initSubmenu();
    this._initStickySidebar();
    this._defineCustomElements();

    rescroll();
  }

  _initSignupSegments() {
    $("[data-signup-form]").signupSegment({
      hash: "teachersignup",
      query: "&SIGNUP=teacher",
      header: "Sign up to receive information about Teacher Programs at MOCA.",
    });
  }

  _initLocations() {
    $("[data=location-exhibit]").matchHeight({
      byRow: false,
      property: "height",
      remove: false,
    });
  }

  _initGoogleSearch() {
    window.__gcse = {
      callback() {
        $("[data-google-input]")
          .find("input[type=text]")
          .attr("placeholder", "Search MOCA");
      },
    };
    if ($("[data-google-input]")) {
      const cx = "001146170153901949142:ndqrq1gnuim";
      const gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = `${
        document.location.protocol === "https:" ? "https:" : "http:"
      }//cse.google.com/cse.js?cx=${cx}`;
      const s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(gcse, s);
    }
  }

  _initStickyHeaders() {
    if (Headroom.cutsTheMustard) {
      let interiorNavOffset;
      $("[data-header-sticky]").headroom({
        offset: 72,
      });

      if ($("[data-sticky]").length > 0) {
        interiorNavOffset = $("[data-sticky]").offset().top;
      }

      $("[data-sticky]").headroom({
        offset: interiorNavOffset - 58,
        classes: {
          notTop: "fixed",
        },
      });
    }
  }

  _initTabs() {
    $("[data-tabs]").tabs();
  }

  _initReadMoreContent() {
    $("[data-rte-content]").readMore();
  }

  _initStillsFaders() {
    $("[data-stills]").stillsFader();
  }

  _initAnchors() {
    $("[data-active-anchors]").activeAnchor();
  }

  _initFitVids() {
    $("[data-video-responsive]").fitVids();
  }

  _initVeggieburgers() {
    $("[data-hamburger-popup]").veggieburger({
      triggers: "[data-hamburger-button]",
      toggle: "[data-hamburger-nav]",
      closers: "[data-hamburger-close]",
      outside: true,
      onToggleOn() {
        $("[data-search-popup]")
          .find("[data-search-input]")
          .removeClass("open");
      },
    });

    $("[data-sitemap-nested]").veggieburger({
      triggers: "[data-nested-trigger]",
      transitionHeight: "[data-nested-list]",
    });

    $("[data-search-popup]").veggieburger({
      triggers: "[data-search-button]",
      toggle: "[data-search-input]",
      onToggleOn(el) {
        $(el).parent().prev().find(":text").focus();
      },
      onToggleOff(el) {
        $(el).parent().prev().find(":text").blur();
      },
    });

    $("[data-overlay-scroll]").veggieburger({
      toggle: "[data-overlay]",
      triggers: "[data-overlay-trigger]",
      closers: "[data-overlay-close]",
      hash: "[data-overlay-hash]",
      onToggleOn() {
        $("body").addClass("no-scroll open");
      },
      onToggleOff() {
        $("body").removeClass("no-scroll open");
      },
    });

    $("[data-pagination-filters]").veggieburger({
      triggers: "[data-filters-trigger]",
      transitionHeight: "[data-filters]",
    });

    $("[data-nav-dropdown]").veggieburger({
      triggers: "[data-dropdown-trigger]",
      toggle: "[data-dropdown-panel]",
      outside: true,
    });

    $("[data-list-dropdown-phone]").veggieburger({
      triggers: "[data-list-dropdown-trigger]",
      closers: "[data-list-dropdown-link]",
      transitionHeight: "[data-list-dropdown]",
      afterHeightOff(event) {
        if ($(event.target).attr("href")) {
          window.location = $(event.target).attr("href");
        }
      },
    });

    $("[data-show-more-peek]").veggieburger({
      triggers: "[data-show-more-toggle]",
      transitionHeight: "[data-show-more-detail]",
      transitionSpeed: 400,
    });

    $("[data-touch-hover]").veggieburger({
      triggers: "[data-hover-trigger]",
      toggle: "[data-hover-target]",
      outside: true,
    });

    $("[data-view-all]").veggieburger({
      triggers: "[data-view-all-trigger]",
      toggle: "[data-view-all-target]",
      outside: false,
    });

    $("[data-view-all-mobile]").veggieburger({
      triggers: "[data-view-all-mobile-trigger]",
      toggle: "[data-view-all-mobile-target]",
      outside: false,
    });
  }

  _initGalleries() {
    const options = {
      namespace: "lightbox",
      closeOnClick: "background",
      beforeOpen() {
        new GalleryExtras(this).init();
      },
      afterOpen() {
        new GalleryExtras(this).bindClose();
      },
      afterContent() {
        new GalleryExtras(this).crossfade();
      },
      beforeClose() {
        new GalleryExtras(this).destroy();
      },
    };
    $("[data-lightbox-single]").featherlight("image", options);
    $("[data-lightbox-gallery]").featherlightGallery(options);
  }

  _initDataFilters() {
    // Add a querystring to the current url when selecting from data-filters select fields
    $("[data-filters] select").each(function () {
      $(this).change(function () {
        let param, qs;
        const thisParam = $(this).data("filter-param");
        const val = $(this).val();

        const ourParams = {};
        if ((qs = window.location.href.split("?")[1])) {
          for (param of Array.from(qs.split("&"))) {
            const bits = param.split("=");
            ourParams[bits[0]] = bits[1];
          }
        }

        if (val === "") {
          delete ourParams[thisParam];
        } else {
          ourParams[thisParam] = val;
        }
        let url = window.location.href.split("?")[0];
        if (Object.keys(ourParams).length > 0) {
          url += `?${$.param(ourParams)}`;
        }
        window.location = url;
      });
    });
  }

  _initCaptions() {
    $("[data-caption-corner-reveal]").captionStretch();
  }

  _initFragmentedForms() {
    $("[data-fragmented-form]").fragmentedForm();
  }

  _initExternalProductQuantities() {
    if (document.querySelectorAll("[data-donation-external-form]").length) {
      const extDonationForm = document.querySelector(
        "[data-donation-external-form]"
      );
      const extDonation = new ExternalProductQuantity(
        extDonationForm,
        "278443458576"
      );
    }
  }

  _initOverlays() {
    if ($("[data-overlay-trigger]").length) {
      $("[data-overlay-trigger]").overlays();
    }
  }

  _initColumnizers() {
    if ($("[data-grid=screen-grid]").length) {
      $("[data-grid=screen-grid]").columnizers({ 700: 2, 1150: 3 });
    }
  }

  _initScreenVideo() {
    if ($("[data-screen-video]").length) {
      $("[data-screen-video]").screenVideo();
    }
  }

  _initSetAlertBarDimensions() {
    if ($("[data-alert-bar]").length) {
      $("[data-alert-bar]").setAlertBarDimensions();
    }
  }

  _initObjectFit() {
    if ($("[data-screen-video]").length) {
      objectFitImages($("img.object-fit"));
    }
  }

  _initSubmenu() {
    $("[data-has-submenu]").submenu();
  }

  _initStickySidebar() {
    $("[data-sticky-sidebar]").stickySidebar();
  }

  _defineCustomElements() {
    customElements.define("flickity-carousel", FlickityCarousel);
  }
}
