import debounce from "lodash/debounce";

export default class StickySidebar {
  constructor(el) {
    this.updateCssVar = this.updateCssVar.bind(this);
    this.$el = $(el);

    this.updateCssVar();
    this.bindResize();
    this.bindScroll();
  }

  updateCssVar() {
    const box = this.$el[0].getBoundingClientRect();
    this.$el[0].style.setProperty(
      "--nav-sticky-top",
      Math.max(0, box.top) + "px"
    );
  }

  bindResize() {
    return $(window).on("resize", debounce(this.updateCssVar, 400));
  }

  bindScroll() {
    return $(window).on("scroll", debounce(this.updateCssVar, 200));
  }
}
