import {
  ActiveAnchor,
  CaptionStretch,
  Columnizer,
  FragmentedForm,
  OverlayHash,
  Overlay,
  ReadMore,
  ScreenVideo,
  SetAlertBarDimensions,
  SignupSegment,
  StickySidebar,
  StillsFader,
  Submenu,
  Tabs,
} from "./behaviors";

$.fn.extend({
  activeAnchor() {
    this.each(function () {
      const anchor = new ActiveAnchor(this);
    });
  },
  captionStretch() {
    this.each(function () {
      const caption = new CaptionStretch(this);
    });
  },
  columnizers(colBreaks) {
    this.each(function () {
      const columnizer = new Columnizer(this, colBreaks);
    });
  },
  fragmentedForm() {
    this.each(function () {
      const form = new FragmentedForm(this);
    });
  },
  overlayHash(options) {
    this.each(function () {
      const hash = new OverlayHash(this, options);
    });
  },
  overlays() {
    this.each(function () {
      const overlay = new Overlay(this);
    });
  },
  readMore() {
    this.each(function () {
      const instance = new ReadMore(this);
    });
  },
  screenVideo(callback) {
    this.each(function () {
      const video = new ScreenVideo(this, callback);
    });
  },
  setAlertBarDimensions() {
    this.each(function () {
      const bar = new SetAlertBarDimensions(this);
    });
  },
  signupSegment(options) {
    this.each(function () {
      const segment = new SignupSegment(this, options);
    });
  },
  stickySidebar() {
    this.each(function () {
      const sidebar = new StickySidebar(this);
    });
  },
  stillsFader() {
    this.each(function () {
      const fader = new StillsFader(this);
    });
  },
  submenu() {
    this.each(function () {
      const menu = new Submenu(this);
    });
  },
  tabs() {
    this.each(function () {
      const t = new Tabs(this);
    });
  },
});
