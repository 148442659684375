export default class Tabs {
  constructor(el) {
    this.activeClass = "active";
    this.$el = $(el);
    this.tabLinks = this.$el.find("[data-tab-link]");
    this.tabContent = this.$el.find("[data-tab-content]");
    this.switchTab(3);
    this.bindLinks();
  }

  bindLinks() {
    this.tabLinks.click((e) => {
      e.preventDefault();
      const $current = $(e.target).closest("li").find("[data-tab-link]");
      this.switchTab($current.data("tabLink"));
    });
  }

  switchTab(activeTab) {
    const self = this;
    this.tabLinks.each(function () {
      $(this).parent("li").removeClass(self.activeClass);
      if ($(this).data("tabLink") === activeTab) {
        $(this).parent("li").addClass(self.activeClass);
      }
    });

    this.tabContent.each(function () {
      $(this).removeClass(self.activeClass);
      if ($(this).data("tabContent") === activeTab) {
        $(this).addClass(self.activeClass);
      }
    });
  }
}
