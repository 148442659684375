export default class CaptionStretch {
  constructor(el) {
    this.$el = $(el);
    this.hoverTimer = null;
    this.hoverOutTimer = null;
    this.hovering = false;
    this.stretched = false;

    this.bindHover();
  }

  bindHover() {
    this.$el.hover(
      () => {
        this.hovering = true;
        this.hoverTimer = setTimeout(() => {
          if (this.hovering) {
            if (!this.stretched) {
              this.initCaptionCenter();
              this.captionAnimateOn();
            }
          } else {
            clearTimeout(this.hoverTimer);
          }
        }, 400);
      },
      () => {
        this.hovering = false;
        clearTimeout(this.hoverTimer);
        if (this.stretched) {
          this.captionAnimateOff();
        }
      }
    );
  }

  initCaptionCenter() {
    const $containerContent = this.$el.find("[data-caption-corner-show]");
    $containerContent.css("display", "block");
    $containerContent.css(
      "paddingTop",
      this.getCenterTop($containerContent, this.$el.innerHeight() * 0.76)
    );
    $containerContent.css("display", "none");
  }

  captionAnimateOn() {
    const $captionContainer = this.$el.find("[data-caption-corner]");
    this.$el.find("[data-caption-corner-hide]").animate(
      {
        opacity: 0,
      },
      200,
      function () {
        $(this).css("display", "none");
      }
    );
    setTimeout(() => {
      this.$el
        .find("[data-caption-corner-show]")
        .css("display", "block")
        .animate(
          {
            opacity: 1,
          },
          200
        );
    }, 150);
    $captionContainer.animate(
      {
        minWidth: "92%",
        minHeight: "80%",
        padding: "0 1.25em",
        bottom: "10%",
        left: "4%",
      },
      250,
      () => {
        $captionContainer.animate(
          {
            minWidth: "88%",
            maxWidth: "88%",
            minHeight: "76%",
            bottom: "12%",
            left: "6%",
          },
          100,
          () => {
            this.stretched = true;
            this.hoverOutTimer = setTimeout(() => {
              if (!this.hovering && this.stretched) {
                this.captionAnimateOff();
              } else {
                clearTimeout(this.hoverOutTimer);
              }
            }, 1500);
          }
        );
      }
    );
  }

  captionAnimateOff() {
    const $captionContainer = this.$el.find("[data-caption-corner]");
    this.$el.find("[data-caption-corner-show]").animate(
      {
        opacity: 0,
      },
      150,
      function () {
        $(this).css("display", "none");
      }
    );
    setTimeout(() => {
      this.$el
        .find("[data-caption-corner-hide]")
        .css("display", "block")
        .animate(
          {
            opacity: 1,
          },
          200
        );
    }, 150);
    $captionContainer.animate(
      {
        minWidth: "0%",
        maxWidth: "75%",
        minHeight: "0%",
        padding: "0.5em",
        bottom: 0,
        left: 0,
      },
      250,
      () => {
        $captionContainer.animate(
          {
            padding: "1.063em 1.25em 1.5em 1em",
          },
          100,
          () => {
            this.stretched = false;
          }
        );
      }
    );
  }

  getCenterTop($element, $containerHeight = null) {
    let $output = 0;
    if ($containerHeight === null) {
      $output =
        ($element.parent("figcaption").height() - $element.height()) / 2;
    } else {
      $output = ($containerHeight - $element.height()) / 2;
    }
    return $output;
  }
}
