import Flickity from "flickity";
// eslint-disable-next-line
import FlickityFade from 'flickity-fade';

export default class FlickityCarousel extends HTMLElement {
  constructor() {
    super();

    this.carousel = null;

    const self = this;
    this.state = new Proxy(
      {
        active: 0,
      },
      {
        set(state, key, value) {
          const oldValue = state[key];

          state[key] = value;
          if (oldValue !== value) {
            self.processStateChange();
          }
          return state;
        },
      }
    );
  }

  get carouselWrapper() {
    return this.querySelector("[data-ref='carousel']") ?? this;
  }

  get prevButton() {
    return this.querySelector("button[data-dir='previous']");
  }

  get nextButton() {
    return this.querySelector("button[data-dir='next']");
  }

  get buttons() {
    return [this.prevButton, this.nextButton].filter(Boolean);
  }

  get slideCount() {
    return this.carousel.slides.length ?? 0;
  }

  connectedCallback() {
    const isReducedMotion =
      window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

    this.carousel = new Flickity(this.carouselWrapper, {
      cellAlign: "left",
      dragThreshold: 15,
      selectedAttraction: 0.075,
      friction: 0.48,
      pageDots: false,
      prevNextButtons: false,
      lazyLoad: 2,
      wrapAround: true,
      adaptiveHeight: false,
      setGallerySize: false,
      autoPlay: 4500,
      pauseAutoPlayOnHover: true,
      fade: isReducedMotion,
    });

    if (!this.carousel || !this.slideCount) return;

    this.carousel.on("change", (index) => (this.state.active = index));

    this.buttons.forEach((button) => {
      button.addEventListener("click", this.handleNavClick);
    });
  }

  disconnectedCallback() {
    if (!this.carousel || !this.slideCount) return;

    this.buttons.forEach((button) => {
      button.addEventListener("click", this.handleNavClick);
    });
  }

  handleNavClick = (event) => {
    const dir = event.target.dataset.dir;
    const active = this.state.active;
    this.carousel.pausePlayer();

    if (dir === "previous") {
      this.state.active = active === 0 ? this.slideCount - 1 : active - 1;
    } else if (dir === "next") {
      this.state.active = active === this.slideCount - 1 ? 0 : active + 1;
    }
  };

  processStateChange() {
    this.carousel.select(this.state.active);
  }
}
