// Gallery Extras
// Methods to be used with featherlight lightbox/gallery

// This class adds the following functionality to the featherlight gallery:
// Adds custom markup that shows images as flexible/responsive background images (with CSS instead of JS)
// Adds custom markup for controls
// Crossfades between custom markup by adding the "next" gallery entry and fading it before deliting
// Adds a class that prevents scrolling on the body (and removes it on close)

// NB: All of these methods are run within/as part of callbacks from featherlight
// Methods are heavily dependent on project specific markup, but techniques for expanding gallery
// functionality in this was is somewhat portable

export default class GalleryExtras {
  // Constructor is passed gallery by featherlight plugin callback
  constructor(gallery) {
    this.gallery = gallery;
    this.slides = this.gallery.slides ? this.gallery.slides() : [];
    this.isGallery = this.slides.length > 1;
    this.hitSafe = [
      ".lightbox-image",
      ".lightbox-caption",
      ".lightbox-previous",
      ".lightbox-next",
      ".product-lightbox-video",
    ];
  }

  init() {
    $("body").addClass("no-scroll");
    this.initFirst();
    this.addControlMarkup();
  }

  destroy() {
    // Doesn't actually destroy the element, just removes scrolling class from body
    $("body").removeClass("no-scroll");
    $("body").unbind("click");
  }

  bindClose() {
    $("body").click((e) => {
      e.preventDefault();
      let hit = true;
      for (const selector of Array.from(this.hitSafe)) {
        if ($(e.target).closest(selector).length > 0) {
          hit = false;
        }
      }
      if ($(e.target).data("productVideoThumb")) {
        hit = false;
      }
      if (hit) {
        $.featherlight.close();
      }
    });
  }

  initFirst() {
    // Get the first img or video with custom markup then add init flag and append to gallery instance
    const $current = this.gallery.$currentTarget;
    const $first = this.getSlide($current);
    $first.data("init", true);
    $first
      .append(this.getTextMarkup($current))
      .appendTo(this.gallery.$instance);
  }

  getSlide(current) {
    let mediaLightbox;
    if (current.data("lightboxVideo")) {
      const lightboxVideo = $(
        '<div class="lightbox-video" data-video-responsive>'
      ).wrapInner(
        `<iframe src="${current.data(
          "lightboxVideo"
        )}" frameborder="0" allowfullscreen=""></iframe>`
      );
      mediaLightbox = $('<figure class="media-lightbox">').wrapInner(
        lightboxVideo
      );
    } else if (current.data("lightboxSelfVideo")) {
      mediaLightbox = $(
        '<figure class="media-lightbox" style="display:none;">'
      );
    } else {
      // Similar to addImageAsBg, but for secondary slides
      const imgUrl = `url(${current.find("img").attr("src")})`;
      const lightboxImage = $('<div class="lightbox-image">').css(
        "background-image",
        imgUrl
      );
      mediaLightbox = $('<figure class="media-lightbox">').wrapInner(
        lightboxImage
      );
    }
    return mediaLightbox.append(this.getTextMarkup(current));
  }

  sanitizeCaption(text) {
    // Sanitize caption for erroneous 'no width space character' &#8203 (unicode 200B)
    return text.replace(/\u200B/g, "");
  }

  getTextMarkup(current) {
    // Takes the current slide data and returns text markup. Used on initial and subsequent slide builds
    // Title not currently used
    // title = current.data('lightboxTitle')
    // lightboxCaptionText = $('<div class="container">').html('<h2 class="heading-secondary">' + title + '</h2>')
    let lightboxCaptionText;
    let desc = current.data("lightboxDesc")
      ? current.data("lightboxDesc")
      : null;

    if (desc) {
      desc = this.sanitizeCaption(desc);
      lightboxCaptionText = $('<div class="container">').html(desc);
    }

    return $('<figcaption class="lightbox-caption">').wrapInner(
      lightboxCaptionText
    );
  }

  addControlMarkup() {
    // Adds some custom markup
    this.gallery.$instance
      .find(".lightbox-content")
      .addClass("lightbox-controls");
  }

  setVideoHeight(video) {
    const $topOffset = (video.parent().height() - video.outerHeight()) / 2;
    return video.css("marginTop", $topOffset);
  }

  bindVideoHeight(video) {
    this.setVideoHeight(video);
    $(window).resize(() => {
      setTimeout(() => {
        this.setVideoHeight(video);
      }, 1000);
    });
  }

  crossfade() {
    // Don't do this for the first gallery entry
    const mediaLightboxCurrent = this.gallery.$instance.find(".media-lightbox");
    if (!mediaLightboxCurrent.data("init")) {
      const $current = this.gallery.$currentTarget;
      const mediaLightboxNext = this.getSlide($current).css("opacity", 0);
      mediaLightboxNext.appendTo(this.gallery.$instance);

      mediaLightboxCurrent.animate(
        {
          opacity: 0,
        },
        400,
        () => mediaLightboxCurrent.remove()
      );
      mediaLightboxNext.animate(
        {
          opacity: 1,
        },
        200
      );
    } else if (this.isGallery) {
      // Remove checker for first gallery image
      mediaLightboxCurrent.data("init", false);
    }
    // If current slide is a video, run fitVids on it and bind resize
    if (this.gallery.$currentTarget.data("lightboxVideo")) {
      const $currentVideo = this.gallery.$instance.find(
        "[data-video-responsive]"
      );
      $currentVideo.fitVids();
      this.bindVideoHeight($currentVideo);
    }
  }
}
