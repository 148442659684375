import debounce from "lodash/debounce";

export default class SetAlertBarDimensions {
  constructor(el) {
    this.setHeight = this.setHeight.bind(this);
    this.$el = $(el);

    this.setHeight();
    this.bindResize();
  }

  setHeight() {
    const alertBarHeight = this.$el[0].getBoundingClientRect().height;
    document.documentElement.style.setProperty(
      "--alert-bar-height",
      alertBarHeight + "px"
    );
    document.documentElement.style.setProperty(
      "--alert-bar-width",
      document.documentElement.clientWidth + "px"
    );
  }

  bindResize() {
    $(window).on("resize", debounce(this.setHeight, 200));
  }
}
