export default class ExternalProductQuantity {
  constructor(form, id) {
    // Class should be instantiated with a dom element that contains an input and a submit button
    // ID parameter tells the class what shopify product variant to use
    this.storeUrl = "http://mocastore.org/cart/";

    this.form = form;

    // Currently we're only using one input, so refactor this if there needs to be more than one
    this.quantityInput = form.querySelector('input[type="number"]');

    // Bind click behavior
    this.form
      .querySelector('input[type="submit"]')
      .addEventListener("click", (event) => {
        event.preventDefault();
        this.addProduct(id);
      });
  }

  addProduct(variantId) {
    this.clearErrors();
    const quantity = this.quantityInput.value;

    // Test that quantity is an integer
    const isInteger = /^(([0-9])+)$/;

    if (isInteger.test(quantity)) {
      this.addUrl =
        this.storeUrl + "add?id=" + variantId + "&quantity=" + quantity;
      window.location = this.addUrl;
    } else {
      this.showError(
        "Please enter a whole number amount without decimals, symbols, or letters."
      );
    }
  }

  showError(errorText) {
    const errorMessage = document.createTextNode(errorText);
    const errorContainer = document.createElement("div");
    errorContainer.appendChild(errorMessage);
    if (errorContainer.classList) {
      errorContainer.classList.add("field-error-single");
      errorContainer.classList.add("emphasized");
    } else {
      errorContainer.className += " field-error-single";
      errorContainer.className += " emphasized";
    }
    errorContainer.setAttribute("data-error", "");
    // Insert error before the first div
    this.form.insertBefore(errorContainer, this.form.querySelector("div"));
  }

  clearErrors() {
    const errors = this.form.querySelectorAll("[data-error]");
    if (errors) {
      Array.from(errors).map((error) => this.form.removeChild(error));
    }
  }
}
