require("./lib/history.js/jquery.history.js");

(function () {
  // ignores hash, query string, and last slash
  const cleanUrl = function (url) {
    return url
      .replace(/#.*/, "") // strip hash
      .replace(/\?.*/, "") // strip query
      .replace(/\/$/, ""); // strip last slash
  };

  let pushedInitialState = false;

  // should only be instantiated once
  let states = null;

  // history.js will trigger a statechange when we push our own custom states
  // so we need to pause listening to ignore those
  // see https://github.com/browserstate/history.js/issues/47
  let listening = true;

  // simple history api wrapper
  function States() {
    // map URLs to callbacks
    this.routeStates = {};

    if (States.enabled()) {
      History.Adapter.bind(
        window,
        "statechange",
        this.triggerPopStateListeners.bind(this)
      );
    }
  }

  States.enabled = function () {
    return true;
  };

  // tie callback to a certain href (popstate)
  States.prototype.register = function (href, cb) {
    this.routeStates[href] = cb;
    if (this.hrefMatch(window.location.href, href) && !pushedInitialState) {
      pushedInitialState = true;
      this.travel(window.location.href);
    }
  };

  States.prototype.hrefMatch = function (alpha, beta) {
    return cleanUrl(alpha) === cleanUrl(beta);
  };

  // push a state with a callback once yer state is popped
  States.prototype.travel = function (state) {
    if (!States.enabled()) return;

    listening = false;
    History.pushState({ ajaxState: state }, "", state);
    listening = true;
  };

  // invoke URL callbacks
  States.prototype.triggerPopStateListeners = function (event) {
    if (!listening) {
      return;
    }
    const obj = History.getState();
    if (obj && obj.data && obj.data.ajaxState) {
      const cb = this.routeStates[obj.data.ajaxState];
      if (cb) {
        cb(event, obj.data.ajaxState);
      }
    } else {
      History.back();
    }
  };

  // a class for making deep links ajaxified:
  // the october framework handles the AJAX request and updating the DOM with no content
  // the purpose of this class is to update the URL (using the States API)
  function AjaxLink($el, name) {
    if (!states) {
      states = new States();
    }

    this.$el = $el;
    this.name = name;

    // temporary flag for doing requests without re-triggering pushstate
    this.noHistory = false;

    // register our href and pop state handler
    states.register(this.href(), this.requestAgain.bind(this));
  }

  AjaxLink.prototype.onSuccess = function () {
    this.travel();
  };

  AjaxLink.prototype.href = function () {
    let href;

    // for "select" elements, use options' data-ajax-link value
    if (this.isSelectTag()) {
      href = this.optionHref();
    }

    // for most "a" tags, use the "href" attribute
    if (!href) {
      href = this.$el.attr("href");
    }

    // For others, use the value of the "data-ajax-link" attribute
    if (!href) {
      href = this.$el.data("ajax-link");
    }

    return href;
  };

  AjaxLink.prototype.isSelectTag = function () {
    return this.$el.prop("tagName").toLowerCase() === "select";
  };

  AjaxLink.prototype.setSelection = function (state) {
    this.$el.find("option:selected").prop("selected", false);
    this.$el
      .find("option" + AjaxLink.dataAttrWhere(state))
      .prop("selected", true);
  };

  AjaxLink.prototype.selectedOption = function () {
    return this.$el.find("option" + AjaxLink.dataAttr + ":selected").first();
  };

  AjaxLink.prototype.optionHref = function () {
    return AjaxLink.dataAttrValue(this.selectedOption());
  };

  // update the URL
  AjaxLink.prototype.travel = function () {
    this.activateParent();
    if (!this.noHistory) {
      states.travel(this.href());
    }
    this.noHistory = false;
  };

  // invoked by on pop state
  AjaxLink.prototype.requestAgain = function (event, state) {
    this.noHistory = true;

    if (this.isSelectTag()) {
      // IE FIX
      // for whatever reason, IE does not retain our element's <option> tags.
      // We need to re-discover the element from the DOM to actually work with it
      this.$el = AjaxLink.elByName(this.name);

      this.setSelection(state);
      this.$el.data("request-data", this.selectedOption().data("request-data"));
    }

    if (!this.reRequester) {
      this.reRequester = AjaxLink.makeNewRequestSender(false).bind(
        this.$el.get(0)
      );
    }
    this.reRequester();
  };

  // if these links are navs, we may need to show active state
  AjaxLink.prototype.activateParent = function () {
    const $activables = $(this.$el.data("ajax-link--activables"));
    const $activate = $(this.$el.data("ajax-link--activate"));
    const activeClass = this.$el.data("ajax-link--active-class");

    $activables.removeClass(activeClass);
    $activate.addClass(activeClass);
  };

  /**
   * On click or change events for elements with 'data-request' attributes,
   * october simply invokes its ajax framework like `$(this).request()` and
   * that's it. Pretty simple. So we're replacing this with the same, except
   * passing in a different success handler to trigger the onSuccess function
   * on the AjaxLink instance.
   *
   * Based on:
   * https://github.com/octobercms/october/blob/ab432c0ee1cc86a6246402c108fb6de5cf4afb2e/modules/system/assets/js/framework.js#L368-L375
   */
  AjaxLink.makeNewRequestSender = function (shouldReturnFalse) {
    return function ajaxLinkRequestSender() {
      // get the "name" for this AjaxLink, and we can re-discover it from
      // the DOM later after replaced via AJAX
      AjaxLink.newFromEl(this).onSuccess();

      // Replace the "success" handler with our own version
      // that will trigger a callback on our AjaxLink object
      $(this).request($(this).data("request"));

      if (shouldReturnFalse) {
        return false;
      }
    };
  };

  AjaxLink.changeSelectors = function () {
    return [
      "select[data-request]",
      "input[type=radio][data-request]",
      "input[type=checkbox][data-request]",
    ]
      .map(function (x) {
        return x + AjaxLink.dataAttr;
      })
      .join(", ");
  };

  AjaxLink.clickSelectors = function () {
    return [
      "a[data-request]",
      "button[data-request]",
      "input[type=button][data-request]",
      "input[type=submit][data-request]",
    ]
      .map(function (x) {
        return x + AjaxLink.dataAttr;
      })
      .join(", ");
  };

  AjaxLink.allSelectors = function () {
    return AjaxLink.changeSelectors() + ", " + AjaxLink.clickSelectors();
  };

  /**
   * October's ajax framework relies on jquery events to trigger various events
   * about the ajax request progress: ajaxDone, ajaxSuccess, ajaxFail, etc. Unfortunately,
   * this not a reliable way of messaging and for whatever reason some triggers do not get
   * sent because the event was deliberately or accidentally not propagated.
   *
   * Fortunately, when october registers the "click" and "change" jquery events, it gives
   * the handling functions names. We can use these names to remove those particular events
   * without blanketly removing all click/change events. After that, we can add our own behavior.
   */
  AjaxLink.replaceOctoberAjaxStuff = function () {
    function removeFrameworkListeners(event, funcName) {
      const events = $._data(document, "events");
      if (events[event]) {
        $.each(events[event], function (i, obj) {
          if (obj && obj.handler && obj.handler.name === funcName) {
            $(document).off(event, obj.selector, obj.handler);
          }
        });
      }
    }

    removeFrameworkListeners("click", "documentOnClick");
    removeFrameworkListeners("change", "documentOnChange");

    $(document).on(
      "change",
      AjaxLink.changeSelectors(),
      AjaxLink.makeNewRequestSender(false)
    );
    $(document).on(
      "click",
      AjaxLink.clickSelectors(),
      AjaxLink.makeNewRequestSender(true)
    );
  };

  $(function () {
    // Static functions reference the needed data attributes
    AjaxLink.dataAttr = "[data-ajax-link]";
    AjaxLink.dataAttrWhere = function (where) {
      return '[data-ajax-link="' + where + '"]';
    };
    AjaxLink.dataAttrValue = function ($el) {
      return $el.data("ajax-link");
    };
    AjaxLink.elByName = function (name) {
      return $('[data-ajax-link][data-ajax-link-name="' + name + '"]').first();
    };
    AjaxLink.newFromName = function (name) {
      return AjaxLink.newFromEl(AjaxLink.elByName(name));
    };
    AjaxLink.newFromEl = function (el) {
      return new AjaxLink($(el), $(el).data("ajax-link-name"));
    };

    // Our special things here
    AjaxLink.replaceOctoberAjaxStuff();

    // Instantiate the things
    $(AjaxLink.allSelectors()).each(function (i, el) {
      AjaxLink.newFromEl(el);
    });
  });
})();
