export default class Overlay {
  constructor(trigger) {
    this.activeClass = "overlay-open";
    this.$trigger = $(trigger);
    this.triggerId = this.$trigger.data("overlay-trigger");

    if (!this.triggerId.length > 0) {
      return;
    }

    this.$overlay = $(`[data-overlay=${this.triggerId}]`);

    if (this.triggerId === "close-all") {
      this.$trigger.on({
        click: (e) => {
          this.closeAllOverlays(e);
        },
      });
    } else {
      this.$trigger.on({
        click: (e) => {
          this.toggleOverlay(e);
        },
      });
    }
  }

  toggleOverlay(e) {
    e.preventDefault();
    if (this.$overlay.hasClass(this.activeClass)) {
      this.closeOverlay();
    } else {
      this.openOverlay();
    }
  }

  closeOverlay() {
    $("[data-overlay-trigger]").removeClass(this.activeClass);
    $("[data-overlay]").removeClass(this.activeClass);
    $("body").removeClass(this.activeClass);

    if ($("[data-stills]").length) {
      $("[data-stills]").trigger("startFader");
    }
  }

  openOverlay() {
    $("[data-overlay-trigger]").removeClass(this.activeClass);
    $("[data-overlay]").removeClass(this.activeClass);
    this.$overlay.addClass(this.activeClass);
    this.$trigger.addClass(this.activeClass);
    $("body").addClass(this.activeClass);

    if ($("[data-stills]").length) {
      $("[data-stills]").trigger("stopFader");
    }
  }

  closeAllOverlays(e) {
    e.preventDefault();
    $("[data-overlay-trigger]").removeClass(this.activeClass);
    $("[data-overlay]").removeClass(this.activeClass);
    $("body").removeClass(this.activeClass);

    if ($("[data-stills]").length) {
      $("[data-stills]").trigger("startFader");
    }
  }
}
