export default class ReadMore {
  constructor(el) {
    this.$content = $(el);
    if (!this.$content.find(".hidden-content-start").length) {
      return;
    }
    this.$el = $(el);

    this.$readMoreStart = this.$content.find(".hidden-content-start").first();
    let $readMoreSiblingStart = null;

    // A comma list of selectors that represent the boundaries of rich text content
    const $boundarySelector = "[data-rte-content]";
    const $ancestors = this.$readMoreStart.parents();
    $ancestors.each(function () {
      const $child = $(this);
      const $parent = $child.parent();
      if ($parent.is($boundarySelector)) {
        $readMoreSiblingStart = $child;
        return false;
      }
    });

    this.$contentsToHide = [
      ...Array.from($readMoreSiblingStart),
      ...Array.from($readMoreSiblingStart.nextAll()),
    ];
    this.$contentsToHide.forEach((content) =>
      $(content).addClass("hidden-content")
    );

    this.$readMores = this.$content.find(".hidden-content");
    this.$readMores.each(function (index) {
      const $item = $(this);
      if (!$item.attr("data-read-more")) {
        $item.attr("data-read-more", index);
        let $morePrev = $item.prev();
        let $moreNext = $item.next();

        while (true) {
          let morePrev = true;
          if ($morePrev.is(".hidden-content")) {
            if ($morePrev.attr("data-read-more")) {
              morePrev = false;
            } else {
              $morePrev.attr("data-read-more", index);
              $morePrev = $morePrev.prev();
            }
          } else {
            morePrev = false;
          }

          if (!morePrev) {
            break;
          }
        }

        while (true) {
          let moreNext = true;
          if ($moreNext.is(".hidden-content")) {
            if ($moreNext.attr("data-read-more")) {
              moreNext = false;
            } else {
              $moreNext.attr("data-read-more", index);
              $moreNext = $moreNext.next();
            }
          } else {
            moreNext = false;
          }

          if (!moreNext) {
            break;
          }
        }

        $(`[data-read-more=${index}]`).wrapAll(
          `<p class='read-more-group' data-read-more-group=${index}></p>`
        );
        const $readMoreGroup = $(`[data-read-more-group=${index}]`);
        $readMoreGroup.before(
          `<div class="read-more-toggle active"><div class="read-more-overlay"></div><a href="#" class="label" data-read-more-group-open=${index}>Read More</a></div>` // eslint-disable-line max-len
        );
        $readMoreGroup.append(
          `<a href="#" class="hide-more-toggle label" data-read-more-group-close=${index}>Hide</a>` // eslint-disable-line max-len
        );

        const $openToggle = $(`[data-read-more-group-open=${index}]`).parent();
        const $closeToggle = $(`[data-read-more-group-close=${index}]`);

        $openToggle.click(function (e) {
          e.preventDefault();
          $openToggle.removeClass("active");
          $closeToggle.addClass("active");
          $readMoreGroup.css("height", "auto");
          const height = $readMoreGroup.height() + "px";
          $readMoreGroup.addClass("open");
          $readMoreGroup.css("height", "0");
          $readMoreGroup.animate({ height }, 400);
        });

        return $closeToggle.click(function (e) {
          e.preventDefault();
          $closeToggle.removeClass("active");
          $openToggle.addClass("active");
          $readMoreGroup.removeClass("open");
          $readMoreGroup.animate({ height: 0 }, 400);
        });
      }
    });
  }
}
