class Rescroll {
  constructor($el, duration, offset) {
    this.scrollEvents =
      "scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove";
    this.$el = $el;
    this.duration = duration;
    this.offset = offset;
    this.animation = { scrollTop: this.$el.offset().top + this.offset };
    this.$body = $("html, body");
    this.listenForScrollInterruption();
    this.animate();
  }

  listenForScrollInterruption() {
    this.$body.one(
      this.scrollEvents,
      this.scrollInterruptionHandler.bind(this)
    );
  }

  animate() {
    this.$body.animate(this.animation, this.duration);
  }

  scrollInterruptionHandler() {
    this.$body.stop();
  }
}

export default function rescroll(
  selector = "[data-rescroll]",
  duration = 1000,
  offset = -150
) {
  $(document).on("ajaxUpdate", selector, function () {
    const scroll = new Rescroll($(this), duration, offset);
  });
}
