export default class FragmentedForm {
  constructor(el) {
    this.$form = $(el);
    const formKey = this.$form.attr("id");
    const $fragments = $(`[data-form-fragment='${formKey}']`);

    $fragments.filter("select").change((e) => {
      this.updateAndSubmit($(e.target));
    });

    $fragments.filter("button").click((e) => {
      this.updateAndSubmit($(e.target));
    });
  }

  updateAndSubmit($el) {
    let unset;
    const selector = `[name='${$el.attr("name")}']`;
    if ((unset = $el.data("unset"))) {
      this.$form.find(`[name='${unset}']`).val("");
    }
    this.$form.find(selector).val($el.val());
    this.$form.submit();
  }
}
