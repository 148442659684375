import debounce from "lodash/debounce";

export default class Columnizer {
  constructor(grid, colBreaks) {
    this.resizeCols = this.resizeCols.bind(this);
    this.colBreaks = colBreaks;
    this.$grid = $(grid);
    this.gridId = this.$grid.data("grid");
    this.$gridItems = this.$grid.find(`[data-grid-item=${this.gridId}]`);

    $(window).on("resize", debounce(this.resizeCols, 200));

    this.resizeCols();
  }

  resizeCols() {
    let currentBreakpoint = 0;
    const cols = [];
    let currentCol = 0;

    const iterable = Object.keys(this.colBreaks);
    for (let i = 0; i < iterable.length; i++) {
      const breakpoint = iterable[i];
      if (this.minWidth(breakpoint)) {
        currentBreakpoint = breakpoint;
      }
    }

    const currentNumCols = this.colBreaks[currentBreakpoint] || 1;

    for (
      let colNum = currentNumCols, asc = currentNumCols <= 0;
      asc ? colNum < 0 : colNum > 0;
      asc ? colNum++ : colNum--
    ) {
      cols.push([]);
    }

    if (!this.$grid.find(".col-hidden").length) {
      this.$gridItems.wrapAll('<div class="col-hidden"></div>');
    } else {
      this.$grid.find(".col").remove();
    }

    const $gridItemClones = this.$gridItems.clone().appendTo(this.$grid);

    $gridItemClones.each(function (gridItem) {
      if (currentCol >= currentNumCols) {
        currentCol = 0;
      }
      cols[currentCol].push(this);
      currentCol++;
    });

    cols.forEach((col) =>
      $(col).wrapAll(`<div class="col col-${currentNumCols}"></div>`)
    );
  }

  minWidth(width) {
    return window.matchMedia(`(min-width: ${width}px)`);
  }
}
